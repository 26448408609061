<script setup lang="ts">
import { useErrorStore } from '@/stores/error'

const errorStore = useErrorStore()
const errorTitle = errorStore.errorTitle || 'Error'
const errorMessage = errorStore.errorMessage || 'You are not authorized to perform that action'
</script>

<template>
  <main class="container">
    <div style="margin-top: 100px" />
    <h1>{{ errorTitle }}</h1>
    <div class="about">{{ errorMessage }}</div>
  </main>
</template>
