<script setup lang="ts">
import InstanceService from '@/services/instance.service'

const returnToLegacy = () => {
  InstanceService.navigateToDnaRoot()
}
</script>

<template>
  <v-container class="experience-disable">
    <v-app-bar :elevation="2" color="#ffffff" :height="80" class="navbar">
      <img src="@/assets/img/renaissance-dna-logo.png" alt="Renaissance DnA logo" class="logo" />
    </v-app-bar>

    <v-row align="center" justify="center" class="content">
      <v-col cols="4">
        <img
          src="@/assets/img/search-error.png"
          alt="Dog sniffing the ground"
          class="illustration"
        />
      </v-col>
      <v-col cols="5">
        <h2 class="title">No Assessments Found</h2>
        <p class="message">
          We're waiting for your district to enable your new experience. Check in with them for more
          information.
        </p>
        <v-btn color="#146EB3" class="return-btn" @click="returnToLegacy">
          Return to Legacy DnA
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.experience-disable {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.navbar {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.logo {
  padding-left: 40px;
}

.content {
  margin-bottom: 60px;
}

.title {
  font-family: Roboto Slab;
  font-weight: 500;
  font-size: 28px;
  line-height: 36.93px;
  color: #202020;
  padding-bottom: 12px;
}

.message {
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.illustration {
  width: 250px;
  height: 200px;
}

.return-btn {
  border: 2px solid rgba(20, 110, 179, 1);
  border-radius: 20px;
  height: 40px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-top: 28px;
}
</style>
