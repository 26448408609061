<script setup lang="ts">
import { useSearchStore } from '@/stores/search'
import { useAuthStore } from '@/stores/auth'
import { computed, onBeforeMount, ref } from 'vue'
import FilterPreset from '@/components/FilterPreset.vue'
import { watch } from 'vue'
import { useFilterStore } from '@/stores/filters'
import router from '@/router'
import { useDisplay } from 'vuetify'

const { mdAndUp, lgAndUp, xs } = useDisplay()
const searchStore = useSearchStore()
const filterStore = useFilterStore()
const authStore = useAuthStore()
const filters = computed(() => {
  return {
    subject: searchStore.getFilters.subjects.map((subject) => {
      return { variant: 'flat', label: 'subjects', id: String(subject.id), name: subject.name }
    }),
    grades: searchStore.getFilters.grades.map((grade) => {
      return { variant: 'flat', label: 'grades', id: String(grade.id), name: grade.name }
    })
  }
})
const filtersValues = ref(filters.value)
const authedUser = computed(() => authStore.user)

onBeforeMount(async () => {
  await authStore.loadUserFromStorage(true)
})

const unwatch = watch(
  () => authedUser.value,
  () => {
    if (authStore.userCanAccess()) {
      searchStore.fetchGrades()
      searchStore.fetchSubjects()
      unwatch()
    }
  }
)

watch(
  () => filters.value,
  (newVal) => {
    filtersValues.value = newVal
  },
  { deep: true }
)

const handleSelectedValues = (selectedValue: any) => {
  switch (selectedValue.label) {
    case 'grades':
      filtersValues.value.grades = filtersValues.value.grades.map((item) => {
        if (item.id === selectedValue.id) {
          item.variant = item.variant === 'flat' ? 'outlined' : 'flat'
        }
        return item
      })
      break
    case 'subjects':
      filtersValues.value.subject = filtersValues.value.subject.map((item) => {
        if (item.id === selectedValue.id) {
          item.variant = item.variant === 'flat' ? 'outlined' : 'flat'
        }
        return item
      })
      break
  }
}
const handleDone = async () => {
  await filterStore.clearFilters()
  filterStore.updatePresetFilters(
    'Grade',
    filtersValues.value.grades
      .filter((grade) => grade.variant === 'outlined')
      .map((grade) => grade.id)
  )
  filterStore.updatePresetFilters(
    'Subject',
    filtersValues.value.subject
      .filter((subject) => subject.variant === 'outlined')
      .map((subject) => subject.id)
  )
  router.push({ name: 'home' })
}
</script>

<template>
  <v-container class="pa-0" :class="xs ? 'presetContainerMobile' : 'presetContainer'" fluid>
    <v-row class="presetContainer__header presetContainer__header--gray" align="center" no-gutters>
      <v-col cols="12">
        <h1 :class="xs ? 'presetContainer__title_mobile' : 'presetContainer__title'">
          Find over 600+ premium, ready-to-use assessments.
        </h1>
      </v-col>
    </v-row>
    <div class="presetContent">
      <v-sheet
        class="presetContent__sheet justify-center pa-0"
        :width="mdAndUp ? (lgAndUp ? '60vw' : '80vw') : '90vw'"
        height="60vh"
      >
        <h2 class="presetContent__sheet__title mb-6">
          Select the primary subject(s) and grade(s) you teach.
        </h2>
        <FilterPreset
          v-for="(filter, index) in filtersValues"
          :label="index"
          :filter-values="filter"
          @selectedValues="handleSelectedValues"
        />
        <v-row justify="center" class="ma-0">
          <v-col class="presetContent__sheet__container">
            <v-btn
              data-test="done-button-test"
              class="presetContent__sheet__button"
              color="#008272"
              :rounded="4"
              :width="mdAndUp ? (lgAndUp ? '20%' : '25%') : '30%'"
              @click="handleDone"
              >Done</v-btn
            >
          </v-col>
        </v-row>
      </v-sheet>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
.presetContent__sheet__container {
  text-align: center;
}

.presetContent__sheet__title {
  font-family: 'Cerebri Sans Medium';
  font-weight: 500;
  font-size: 20px;
  line-height: 25.4px;
  letter-spacing: 0;
  color: #1d2f45;
}
.presetContent__sheet__button {
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  text-transform: capitalize;
  line-height: 14px;
}
.presetContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  h2 {
    text-align: center;
  }
}

.content__subcontent {
  border: 1px solid;
}
.content {
  height: 295px;
}
.presetContainer {
  height: 95%;
}
.presetContainerMobile {
  height: 99%;
}
.presetContainer__title_mobile,
.presetContainer__title {
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
}
.presetContainer__title_mobile {
  padding: 0 12px;
}
.presetContainer__header {
  height: 10rem;
}
.presetContainer__header--gray {
  background-color: #f1efe3;
}
</style>
