<script setup lang="ts">
import { useFoldersStore } from '@/stores/folders'
import { folderService } from '@/services/folder.service'
import { computed } from 'vue'
import { useFeatureFlagsStore } from '@/stores/feature-flag'
import { FEATURE_FLAGS } from '@/util/feature-flags.constants'
const foldersStore = useFoldersStore()

const clientEnabledFlag = computed(
  () => useFeatureFlagsStore().flags[FEATURE_FLAGS.AM_CLIENT_ENABLED_FLAG]
)

const clearFolders = async () => {
  if (!confirm('Are you sure you want to clear all folders for the current user?')) {
    alert('Operation cancelled.')
    return
  }

  let error = false
  await foldersStore.fetchFolders()
  let folders = foldersStore.getFolders

  const folderPromises = folders.map(async (folder) => {
    try {
      let assessmentIds = folder.assessmentIds
      let folderId = folder.folderId
      if (assessmentIds.length > 0) {
        await folderService.removeAssessmentsFromFolder(folderId, assessmentIds)
      }
      try {
        await folderService.deleteFolder(folderId)
      } catch {
        error = true
      }
    } catch {
      error = true
    }
  })

  await Promise.all(folderPromises)
  if (error) {
    alert('An error occurred while clearing folders.')
  } else {
    alert('Folders cleared successfully.')
  }
}
</script>

<template>
  <main class="container">
    <div style="margin-top: 50px" />
    <h1>
      Dev Tools ::
      <a href="/" style="font-size: 16px">Back to App</a>
    </h1>
    <hr />
    <h3>Feature Flag Validations</h3>
    <p>am-client-enabled-flag: {{ clientEnabledFlag }}</p>
    <hr />
    <p style="color: red">
      Performing any of the following actions has the potential to clear data for the current user
      and is irreversible.
    </p>
    <ul>
      <li>
        <v-btn class="action-btn" variant="text" data-test="back-button" @click="clearFolders">
          Clear <u><b>All</b></u> Folder Data For Current User
        </v-btn>
      </li>
    </ul>
  </main>
</template>

<style scoped>
.container {
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
}
</style>
