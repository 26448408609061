import { defineStore } from 'pinia'

interface ErrorState {
  errorTitle: string | null
  errorMessage: string | null
}

export const useErrorStore = defineStore('error', {
  state: (): ErrorState => ({
    errorTitle: null,
    errorMessage: null
  }),
  actions: {
    setFullError(title: string, message: string) {
      this.errorTitle = title
      this.errorMessage = message
    },
    setErrorTitle(title: string) {
      this.errorTitle = title
    },
    setErrorMessage(message: string) {
      this.errorMessage = message
    }
  }
})
