<script setup lang="ts">
import { ref, watch } from 'vue'
import SearchBar from '../components/SearchBar.vue'
import SearchResults from '../components/SearchResults.vue'
import { useSearchStore } from '@/stores/search'
import { useAuthStore } from '@/stores/auth'
import { useFilterStore } from '@/stores/filters'
import Filters from '@/components/Filters.vue'
import { computed } from 'vue'
import { onBeforeMount } from 'vue'
import { useSpinnerStore } from '@/stores/spinner'
import { useFavoriteStore } from '@/stores/favorites'
import FolderGroup from '@/components/FolderGroup.vue'
import { useFeatureFlagsStore } from '@/stores/feature-flag'
import { FEATURE_FLAGS } from '@/util/feature-flags.constants'
import FolderToolbar from '@/components/FolderToolbar.vue'
import { useFoldersStore } from '@/stores/folders'
import { DatadogService } from '@/services/datadog.service'
import { useDisplay } from 'vuetify'

const { xs } = useDisplay()
const folderStore = useFoldersStore()
const searchStore = useSearchStore()
const spinnerStore = useSpinnerStore()
const authStore = useAuthStore()
const favoriteStore = useFavoriteStore()
const filterStore = useFilterStore()
const filterPreset = computed(() => filterStore.isValuePreSet)
const preSelectedFilters = computed(() => filterStore.currentPreSelectedFilters)
const featureFlagStore = useFeatureFlagsStore()
const loadingData = ref(true)

const folderFlagEnabled = computed(
  () => featureFlagStore.flags[FEATURE_FLAGS.AM_FOLDER_FUNCTIONALITY_FLAG]
)

const authedUser = computed(() => authStore.user)

const unwatch = watch(
  () => authedUser.value,
  async () => {
    if (authStore.userCanAccess()) {
      spinnerStore.loadingAuth = false
      await initHomeView()
      loadingData.value = false
      unwatch()
    }
  }
)

onBeforeMount(async () => {
  spinnerStore.loadingAuth = true
  await authStore.loadUserFromStorage(true)
})

const initHomeView = async () => {
  try {
    const fetchBasics = [
      searchStore.fetchGrades(),
      searchStore.fetchSubjects(),
      searchStore.fetchQuestions(),
      searchStore.fetchAssessmentTypes(),
      favoriteStore.getFavoriteAssessments()
      // For fetching filters, this logic can be found in the index.ts file of the vue router
    ]

    if (folderFlagEnabled.value) {
      fetchBasics.push(folderStore.fetchFolders())
    }

    // Fetch data in parallel to improve performance
    await Promise.all(fetchBasics)

    if (filterPreset.value) {
      await searchStore.fetchSearchResults()
    } else {
      searchStore.updatePresetFilters(preSelectedFilters.value)
    }
  } catch (error) {
    DatadogService.addErrorWithStack('Error initializing home view', { error })
    console.error('Error initializing HomeView:', error)
  }
}
</script>

<template>
  <div class="parent-container">
    <FolderToolbar v-if="folderFlagEnabled && xs" />
    <v-container class="container-v" fluid>
      <v-row v-if="folderFlagEnabled" justify="center" no-gutters>
        <v-col cols="12">
          <SearchBar />
        </v-col>

        <v-row justify="center" no-gutters>
          <v-col class="hide-folder-group mr-6">
            <FolderGroup v-if="folderFlagEnabled && !xs" />
          </v-col>
          <v-col cols="9" class="col-without-folder-group">
            <Filters class="mb-6" />
            <div class="container-content">
              <SearchResults />
            </div>
          </v-col>
        </v-row>
      </v-row>

      <template v-else>
        <v-row justify="center">
          <v-col sm="10" cols="11">
            <SearchBar />
          </v-col>
        </v-row>
        <v-row v-if="!loadingData" justify="center" class="mt-0">
          <v-col sm="10" cols="11" class="pt-0 pb-0">
            <Filters />
          </v-col>
        </v-row>
        <v-row justify="center" class="container-v__content">
          <v-col sm="10" cols="11">
            <section class="main-content">
              <SearchResults />
            </section>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<style scoped>
.parent-container {
  width: 100%;
  height: 100vh;
}
.container-content {
  position: relative;
  width: 100%;
  height: 100%;
}
.container-v__content {
  background-color: #fff;
}
.container-v {
  background-color: #fff;

  padding-left: 0px;
  padding-right: 0px;
  height: 100%;
  max-width: 1042px;
}

.content-below-search {
  display: flex;
  align-items: flex-start;
}

.sidebar {
  width: 300px; /* Fixed width for the sidebar */
  flex-shrink: 0; /* Prevent the sidebar from shrinking */
  /* Additional styling for the sidebar */
  margin-top: 35px;
}

.main-content {
  width: 100%;
  position: relative;
  z-index: 1;
  flex-grow: 1; /* Take up the remaining space */
  /* Additional styling for the main content */
}

.placeholder-component {
  border: 1px dashed #ccc;
  margin: 8px;
  padding: 16px;
  padding-top: 0;
  text-align: center;
}

@media (max-width: 599px) {
  .hide-folder-group {
    display: none;
  }
  .col-without-folder-group {
    flex: 0 0 100%; /* 12/12 columns = 100% */
    max-width: 100%;
  }
}
</style>
