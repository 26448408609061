import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import { useAuthStore } from '@/stores/auth'
import { useFilterStore } from '@/stores/filters'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/assessment/:id',
    name: 'assessment',
    component: () => import('../views/AssessmentDetailView.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/ErrorVue.vue'),
    props: true
  },
  {
    path: '/filtersPreset',
    name: 'filtersPreset',
    component: () => import('../views/FilterPresetsView.vue')
  }
]

// Conditionally add the dev-tools route
if (['dev', 'local', 'docker'].includes(import.meta.env.VITE_ENV)) {
  routes.push({
    path: '/devtools',
    name: 'devtools',
    component: () => import('../views/DevToolsView.vue'),
    props: true
  })
}

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes: routes
})

router.beforeEach(async (to, _from, next) => {
  const authStore = useAuthStore()
  const filterStore = useFilterStore()

  if (authStore.userExistsInSession() && !authStore.user) {
    await authStore.loadUserFromStorage()
  }

  const userHasPresetFilters = authStore.userCanAccess()
    ? await filterStore.fetchPreSelectedFiltersIfExists()
    : false

  if (to.name === 'filtersPreset' && userHasPresetFilters) {
    next({ name: 'home' })
  } else if (to.name !== 'filtersPreset' && !userHasPresetFilters) {
    next({ name: 'filtersPreset' })
  } else {
    next()
  }
})

export default router
