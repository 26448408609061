<script setup lang="ts">
import { onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useAssessmentStore } from '../stores/assessment'
import { useFavoriteStore } from '@/stores/favorites'
import PreviewContent from '@/components/PreviewContent.vue'
import ActionsButtonContent from '@/components/ActionsButtonContent.vue'
import AssessmentHeaderDescription from '@/components/AssessmentHeaderDescription.vue'
import { AssessmentService } from '@/services/assessment.service'

const assessmentStore = useAssessmentStore()
const favoriteStore = useFavoriteStore()
const favorites = computed(() => favoriteStore.currentFavoriteAssessments)
const assessment = computed(() => assessmentStore.assessment)
const route = useRoute()
const assessmentGuid = route.params.id as string

onMounted(async () => {
  assessmentStore.setShowFullScreenButton(false)
  assessmentStore.setShowDescription(true)
  try {
    await assessmentStore.fetchAssessmentDetail(assessmentGuid)
  } catch (error) {
    console.error('Error updating assessment detail:', error)
  }
})
</script>

<template>
  <div v-if="assessment.assessment_id > 0" class="container bg-color">
    <div class="main-container">
      <v-card class="card">
        <AssessmentHeaderDescription
          class="assessmentHeader"
          :assessment="assessment"
          :showLabelAvatar="true"
        />
      </v-card>
      <v-card class="test-content-card">
        <h2 class="card__header" data-test="assessment-type-title-fullscreen">
          {{ AssessmentService.getAssessmentTypeLabel(assessment.assessment_type) }}
          |
          <span style="font-weight: 400"><strong>ID:</strong> {{ assessment.assessment_id }}</span>
        </h2>
        <div class="action-section">
          <h3 class="header__preview">Preview</h3>
          <ActionsButtonContent
            :assessment="assessment"
            :isFavorited="favorites.includes(assessment.assessment_guid)"
          />
        </div>
        <div class="test-content">
          <PreviewContent :assessment="assessment" />
        </div>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
:deep(.containerHeader) {
  align-items: flex-start !important;
}

:deep(.v-main) {
  max-width: auto;
}

.container {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.main-container {
  width: 776px;
  max-width: 776px;
}

.container {
  background-color: #ecedf1;
}

.main-container h3 {
  margin-bottom: 5px;
}

.card {
  margin-top: 56px;
  padding: 36px;
}

.test-content-card {
  margin-top: 10px;
}

.card__header {
  font-family: 'Cerebri Sans';
  font-size: 10px;
  font-weight: 700;
  line-height: 12.7px;
  letter-spacing: 2px;
  margin: 24px 24px 0px;
  text-transform: uppercase;
}

.action-section {
  display: flex;
  align-items: center;
  margin: 10px 24px 10px;
  flex-wrap: wrap;
}

:deep(.body-title) {
  font-family: 'Cerebri Sans';
  font-size: 24px;
  font-weight: 600;
  line-height: 30.48px;
  letter-spacing: 0px;
  text-align: left;
}

.card,
.test-content-card {
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid #e0e1e6;
}

.standard-section {
  padding: 10px 20px;
}

.description {
  margin-top: 30px;
  max-width: 587px;
}

.test-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  max-height: 80%;
  margin: 10px 24px 24px;
}

.assessment-preview {
  width: 100%;
  min-height: 500px;
  border: none;
}

.header__preview {
  flex: 1 1 100px;
  font-size: 16px;
  color: #202020;
  line-height: 20.32px;
  font-weight: 400;
}
</style>
